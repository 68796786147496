<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">List</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="headerName"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="lists" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="testId" md-label="TEST_ID">{{ item.testId }}</md-table-cell>
            <md-table-cell md-sort-by="headerName" md-label="HEADER NAME">{{ item.headerName }}</md-table-cell>
            <md-table-cell md-sort-by="date" md-label="DATE">{{ item.date }}</md-table-cell>
            <md-table-cell md-sort-by="assignTo" md-label="ASSIGN TO" class="text-victoria">{{ item.assignTo }}</md-table-cell>
            <md-table-cell md-sort-by="testName" md-label="TEST NAME">{{ item.testName }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ 'Active' }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onShowDetails" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog >
      <component :questionTypes="questionTypes" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import CreateMockExam from "@/components/molecule/mock-exam/CreateMockExam";
import ShowDetails from "@/components/molecule/mock-exam/ShowDetails";
import cms from "@/data/cms";
import { lists } from "@/data/master/qatm/test-management"
import { mapMutations } from "vuex";
export default {
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        ShowDetails,
        CreateMockExam,
  },
  data() {
      return {
          currentIndex: 0,
          component: 'CreateMockExam',
          questionTypes: '',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
      }
  },
  computed: {
    lists() {
      if(this.pagination) {
        return lists.slice(this.paginate.start, this.paginate.end);
      }
      return lists;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return lists.filter(item => this.toLower(item.headerName).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      this.component = 'ShowDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'CreateMockExam';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    onFilteringData(index) {
      this.currentIndex = index;
      this.onPrepareQueryString('filter', this.toLower(this.buttonFilters[index].name));
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>